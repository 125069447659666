import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { DiscussionEmbed } from 'disqus-react'
import Helmet from 'react-helmet'
import MainLayout from '../layouts'
import config from '../../config'
import Categories from '../components/Categories'
import Author from '../components/Author'
import Linkedin from '../../assets/icons/linkedin.svg'
import Twitter from '../../assets/icons/twitter.svg'
import Facebook from '../../assets/icons/facebook.svg'
import PrevNext from '../components/PrevNext'

const Post = ({ location, pageContext: { slug, prev, next }, data: { mdx: postNode } }) => {
  const {
    body,
    timeToRead,
    frontmatter: {
      title,
      date,
      photoauthor,
      hash,
      categories,
      cover: {
        children: [{ fluid }],
      },
    },
  } = postNode

  const disqusConfig = {
    shortname: 'contend',
    config: { identifier: hash, title },
  }

  const url = location.href
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
  const twitterUrl = `https://twitter.com/home?status=${url}`
  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`

  return (
    <MainLayout>
      <Wrapper>
        <Helmet title={`${title} | ${config.siteTitle}`} />
        <Header>
          <HeaderInfo>
            <Categories categories={categories} />
            <h1>{title}</h1>
            <Author date={date} timeToRead={timeToRead} />
          </HeaderInfo>
          <ImageWrapper>
            <div style={{ backgroundImage: `url(${fluid.src})` }} />
            <p>
              <b>Photo by:</b> {photoauthor}
            </p>
          </ImageWrapper>
        </Header>
        <Body>
          <Social>
            <div className="social-icons">
              <a href={facebookUrl}>
                <Facebook />
              </a>
              <a href={twitterUrl}>
                <Twitter />
              </a>
              <a href={linkedinUrl}>
                <Linkedin />
              </a>
            </div>
          </Social>
          <Content>
            <MDXRenderer>{body}</MDXRenderer>
            <Categories categories={categories} />
          </Content>
          <EmptyColumn />
        </Body>
        <PrevNext prev={prev} next={next} />
        <DiscussionEmbed {...disqusConfig} />
      </Wrapper>
    </MainLayout>
  )
}
const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
`
const ImageWrapper = styled.div`
  flex: 1 1 10rem;

  div {
    width: 100%;
    border-radius: 1.111rem;
    background: 50% no-repeat;
    background-size: cover;
    height: 35rem;
    flex: 1;
  }

  p {
    font-size: 0.8rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    div {
      height: 20rem;
    }
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: normal;
  }
`

const HeaderInfo = styled.div`
  flex-direction: column;
  margin: 2rem 0;
  padding: 0rem 2rem 0rem 0rem;
  flex: 1;
  h1 {
    margin: 1rem 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 0;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    display: block;
  }
`
const Social = styled.div`
  flex: 1 0 12rem;

  .social-icons {
    display: flex;
    flex-direction: column;
    width: 15%;
    align-items: center;
    margin: 1rem 0;
  }

  p {
    font-weight: 600;
  }

  svg {
    margin: 0.7rem 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex: 1 0 8.5rem;
    p {
      max-width: 5rem;
    }
    a {
      display: block;
      padding: 0.6rem 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    .social-icons {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    p {
      max-width: 10rem;
    }
    a {
      margin: 0 0.5rem;
      display: inline-block;
    }
  }
`

const Content = styled.div`
  max-width: 60%;
  line-height: 1.8333rem;
  font-size: 1.15rem;
  img {
    max-width: 100%;
  }
  pre {
    padding: 1rem;
    font-size: 0.9rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    max-width: 100%;
    display: block;

    pre {
      font-size: 0.9rem;
    }
  }
`

const EmptyColumn = styled.div`
  flex: 1 0 11rem;
`

export default Post

Post.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    next: PropTypes.object,
    prev: PropTypes.object,
  }),
  data: PropTypes.shape({
    mdx: PropTypes.object.isRequired,
  }).isRequired,
}

Post.defaultProps = {
  pageContext: PropTypes.shape({
    next: null,
    prev: null,
  }),
}

export const postQuery = graphql`
  query postBySlugQuery($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        title
        photoauthor
        hash
        date(formatString: "MM/DD/YYYY")
        categories
        cover {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 1600, maxHeight: 1600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      timeToRead
      parent {
        ... on File {
          mtime
          birthtime
        }
      }
    }
  }
`
